.footer {
    background-color: black;
    padding: 3% 10% 1%;
}
.contact-details{
  text-align: center;
}
.contact-us {
    font-family: 'Lexend Zetta', sans-serif;
    letter-spacing: 0.5rem;
    color: #ffe100;;
    padding: 2% 15%;
}
.contact-text {
    font-size: 0.8rem;
    line-height: 1.5;
    letter-spacing: 0.2rem;
    color: white;
    opacity: 0.6;
}
.contact-divider{
  width: 40%;
  padding: 0.5rem 0;
  border-color: #d0d0d0;
  opacity: 0.5;
}
.contact-icons{
    color: #b0b0b0;
    margin: 0 3% 1%;
}
.fa-envelope:hover{
    color: #D44638;
}
.fa-whatsapp:hover{
    color: #25d366;
}
.copyright{
    font-size: 0.7rem;
    line-height: 0;
    letter-spacing: 0.3rem;
    color: white;
    opacity: 0.5;
}
@media (max-width: 600px) {
  .footer {
      padding: 2% 4% 1%;
  }
  .contact-us {
      font-family: 'Ubuntu', sans-serif;
      letter-spacing: 0.3rem;
      padding: 2% 7%;
  }
}
