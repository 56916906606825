/* ********************To remove auto increment and decrement icon***************************** */ 
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

input[type=number] {
  -moz-appearance: textfield;
}

/* ************************************************* */ 
.parent-div {
    background-color: #404040;
    color: white;
    overflow: hidden;
}
.profile-info{
  font-family: 'Ubuntu', sans-serif;
  padding: 5% 9% 10%;
}
.profile-image {
    margin-bottom: 3%;
    width: 115px;
    height: auto;
    border-radius: 50%;
    box-shadow: 0 0 4px #092532;
}
.profile-divider{
  text-align: left;
  padding: 1% 0;
  border-color: #092532;
  opacity: 0.5;
}
.email-container{
  color: #d0d0d0;
}
.phno-container{
  width: 100%;
}
.verify-btn{
  background-color: #ffe100;
  border: 0;
  color: #202020;
  height: 2.5rem;
}
.verify-btn:hover{
  background-color: #ffff80;
  color: black;
  transition: 0.2s;
  font-size: 1.01rem;
}
@media (max-width: 670px){
  .profile-info{
    padding: 20% 10%;
  }
  .email-container{
    width: 25rem;
  }
  .phno-container{
    width: 25rem;
  }
  .verify-btn{
    margin: 4% 33%;
  }

}
