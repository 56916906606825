
.items-in-cart{
  box-shadow: 0 0 2px #101010;
  background-color: #303030;
  color: white;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 3px;
}
.items-in-cart .row{
    margin: 0;
}
.item-name{
  font-family: 'Noto Serif', serif;
  font-size: 1.5rem;
}
.xPlate{
  margin: 0.4rem 0 0;
}
.item-qty, .item-price, .multi-x{
  margin: 1rem 0;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.2rem;
}
.xPrice, .xPlate{
  float: right;
}
.calc-btn{
  padding: 0 0.2rem;
}
.cart-btn{
  margin: 0;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  width: 100%;
  background-color: #202020;
  border-radius: 3px;
  border: 0;
}

.remove-btn:hover{
  transition: 0.2s;
  background-color: #d61111;
}

.minus-btn:hover{
  transition: 0.2s;
  background-color: #ffaa00;
}

.plus-btn:hover{
  transition: 0.2s;
  background-color: #3ccc0c;
}
