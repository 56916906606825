body{
  background-color: #404040;
  color: white;
}
.category-menu {
    margin: 0 1.5%;
}
.category-menu ul{
    padding: 0;
    margin: 2px 0 0;
}
.category-menu ul li{
    text-align: center;
    width: auto;
    font-family: 'Ubuntu', sans-serif;
    color: #e0e0e0;
    background-color: #303030;
    font-size: 1.1rem;
    float: left;
    letter-spacing: 0.2rem;
    list-style: none;
    padding: 0.8rem 1rem;
    margin: 2px 0.2rem 0;
    cursor: pointer;
}
.category-menu ul li:hover{
    background-color: #ffe100;
    color: black;
}

.category-drop{
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.3rem;
  list-style: none;
  float: left;
  padding: 1% 2%;
  margin: 2px 0.2rem 0;
  cursor: pointer;
}
.category-btn{
  background-color: #202020;
}
.category-btn:hover{
  background-color: #ffe100;
  color: #202020;
}
.category-list{
  background-color: #303030;
  box-shadow: 1px 1px 3px #101010;
}
.items-card {
    margin: 0 1%;
}
.cart-option {
    background-color:  #202020;
    border-radius: 3px 28px 28px 3px;
    margin: 5px 0 0;
    max-height: 3.2rem;
    font-size: 1.3rem;
    text-align: center;
    cursor: pointer;
}
.cart-option:hover{
    background-color:  #ffe100;
    color: #202020;
}
.cart-icon{
    padding: 1.2rem 0 0.7rem;
}
.cart-items {
    text-align: center;
    font-size: 0.8rem;
    position: absolute;
    margin-top: 0.5rem;
    background-color: black;
    padding: 1% 3%;
    border-radius: 10%;
    color: #fff;
}
/* ***********************show Cart Overlay Css****************************** */

.cart-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
    background: rgb(0, 0, 0, 0.5);
    z-index: 20;
    /*visibility: category-drop;*/
}

.cart-side-bar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    z-index: 3;
    background-color: #404040;
    padding: 1.2rem;
    transition: all 0.3s linear;
    /*transform: translateX(100%);*/
}

.back-btn{
  margin-bottom: 2rem;
  padding: 0;
}
.back-btn:hover{
  color: #ffe100;
}

.cart-footer{
  margin: 1.5rem 0;
}
.clear-cart{
  margin: 0.5rem 0 0;
  font-family: 'Ubuntu', sans-serif;
  width: 50%;
  height: 3rem;
  background-color: #202020;
  border-radius: 3px;
  border: 0;
}
.clear-cart:hover{
  transition: 0.2s;
  background-color: #e30000;
}
@media (min-width: 901px){
  .category-menu {
      min-height: 3rem;
      overflow: hidden;
  }
}
@media screen and (min-width: 650px) {
  .cart-side-bar {
    width: 30vw;
    min-width: 450px;
  }
}

@media (max-width: 575px)
{
  .items-card {
      margin: 3rem 1% 0;
  }
  .category-menu ul li{
      font-size: 1.1rem;
      letter-spacing: 0.2rem;
      padding: 1%;
      margin: 2px 0.1rem 0;
  }

  .cart-items {
      text-align: center;
      font-size: 0.8rem;
      position: absolute;
      margin-top: 0.5rem;
      background-color: black;
      padding: 0 2px;
      border-radius: 10%;
      color: #fff;
  }
  .cart-option{
    width: 25%;
  }
}

@media (max-width: 900px){
  .default-list{
    display: none;
  }
  .category-drop .btn.btn-secondary{
      background-color: #202020;
      border: 0;
  }
  .category-drop .dropdown-menu{
      background-color: #303030;
      color: white;
      width: 15rem;
      padding: 0;
  }
  .category-drop .dropdown-menu ul{
      padding: 0;
      margin: 0;
  }
  .category-drop .dropdown-menu ul li{
      text-align: left;
      font-family: 'Ubuntu', sans-serif;
      color: #e0e0e0;
      background-color: #303030;
      font-size: 1.1rem;
      float: left;
      letter-spacing: 0.3rem;
      list-style: none;
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0;
      cursor: pointer;
  }
  .category-drop .dropdown-menu ul li:hover{
      background-color: #202020;
      font-size: 1.2rem;
  }
}

@media (min-width: 901px)
{
  .category-drop{
    display: none;
  }
}
