.main-div{
   background-color: #404040;
}
.kitchen-signIn{
  text-align: center;
}
.kitchen-text{
  margin: 12% auto;
  color: white;
}
.kitchen-text img{
  width: 35rem;
  height: auto;
  opacity: 0.5;
}
.kitchen-text button{
  border: 0;
  padding: 0;
  margin: 1rem;
  background-color: black;
  border-radius: 2px;
}
.kitchen-text p{
  font-family: 'Ubuntu', sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
}
@media (max-width: 1000px){

}
@media (max-width: 600px) {


}
