/* ********************To remove auto increment and decrement icon***************************** */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
}
/* ********************Restaurant list column***************************** */
.retaurant-col{
    background-color: #303030;
}
.add-restaurant-button {
    transition: 0.2s;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    width: 100%;
    height: 4rem;
    background-color: #303030;
    color: white;
    border: 0;
    border-radius: 0;
}
.add-restaurant-button:hover{
    transition: 0.1s;
    background-color: #202020;
    letter-spacing: 0.21rem;
}
.col-heading{
    padding: 15px 0;
    margin: 0;
    background-color: #ffe100;
    color: black;
}
.main-container .retaurant-col{
    padding: 0;
}
.main-container .menu-col{
    padding: 0;
    background-color: #353535;
}
.restaurant-list {
    height: 100%;
    margin: 0;
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
}
.address-text-area {
    resize: none;
}

/* ********************Menu container column************************* */
.div-to-show-menu {
    height: 120vh;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    ;
}
/* Search bar division */
.searchBar-div {
    display: flex;
    background-color: #ffe100;
    padding: 0.82rem 0.5rem;
    width: inherit;
    margin: 0;
}
.input-group-prepend{
    margin: 0;
}
.input-group-text{
    width: auto;
    background-color: #303030;
    border: 0.5px solid #303030;
    border-radius: 20px 0 0 20px;
}
.searchBar-div .input-group .form-control{
    border-radius: 0 20px 20px 0;
}
.suggestion {
    padding-left: 40px;
    background-color: #404040;
    color: white;
    width: inherit;
    border-radius: 20px;
    box-shadow: 0 0 4px #101010;
    position: absolute;
    top: 2.4rem;
    z-index: 1;
}
.suggestion ul {
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0;
}
.suggestion li {
    border-radius: 0 20px 20px 0;
    padding: 2%;
    cursor: pointer;
}
.suggestion li:hover {
    transition: 0.1s;
    font-size: 1.01rem;
    background-color: #303030;
}
.add-new-item-btn {
    width: 100%;
    font-family: 'Ubuntu', sans-serif;
    letter-spacing: 0.1rem;
    background-color: #ffff80;
    border: 0;
    border-radius: 20px;
    box-shadow: 0 0 3px #d0d0d0;
    height: 38px;
}
.add-new-item-btn:hover {
    transition: 0.2s;
    background-color: black;
    color: #ffe100;
}
/* ***********************Menu category*************************** */
.menu-category {
    display: block;
    min-height: 3rem;
    overflow: hidden;
    margin: 0 1.5%;
}
.menu-category ul{
    padding: 0;
    margin: 2px 0 0;
}
.menu-category ul li{
    text-align: center;
    width: auto;
    font-family: 'Ubuntu', sans-serif;
    color: #e0e0e0;
    background-color: #282828;
    font-size: 1.1rem;
    float: left;
    letter-spacing: 0.3rem;
    list-style: none;
    padding: 0.5rem 0.8rem;
    margin: 2px 0.2rem 0;
    cursor: pointer;
}
.menu-category ul li:hover{
    background-color: #ffe100;
    color: black;
}


/* ********************Add new/Edit Forms****************************** */
.modal-content{
    background-color: #202020;
    color: white;
    align-content: center;
}
.modal-header{
  background-color: #202020;
  color: white;
  border: 0;
  border-radius: 10px 10px 0 0;
  width: 160%;
  position: relative;
  right: 30%;
  box-shadow: 0 -1px 2px black;
}
.Form-title{
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.4rem;
    padding-left: 0.5rem;
    letter-spacing: 0.2rem;
}
.modal-body{
    background-color: #303030;
    border: 0;
    border-radius: 0 0 10px 10px;
    width: 160%;
    position: relative;
    right: 30%;
    box-shadow: 0 2px 4px black;
}
.form-group{
  padding: 0 0.5rem;
}
.form-label{
    font-family: 'Quattrocento Sans', sans-serif;
    font-size: 1.1rem;
}
.form-control, .custom-select{
    background-color: #404040;
    color: #f0f0f0;
    border: 0;
    border-radius: 20px;
    display: block;
}
.form-control:hover, .custom-select:hover{
    transition: 0.2s;
    background-color: #202020;
}
.adv-opt{
  padding-left: 0.75rem;
}
.adv-opt:hover{
  cursor: pointer;
}
.fa-angle-down{
  position: relative;
  padding: 0.35rem 0.5rem 0.25rem;
  border-radius: 15px;
  top: 2px;
  left: 3px;
}
.sub-row{
  padding: 0 0.75rem;
}
.sub-col{
  padding: 0.2rem 0.25rem;
}
.del-btn{
  background-color: #202020;
  color: white;
  border: 0;
  border-radius: 30px;
  padding: 0.5rem 0.8rem;
}
.add-sub-div{
  text-align: center;
  padding-bottom: 0.5rem;
}
.custom-switch{
  display: inline-block;
  margin-left: 1rem;
}
.custom-switch{
  display: inline-block;
  margin-left: 1rem;
}
.custom-switch .custom-control-label::before{
  background-color: red;
}
.custom-switch .custom-control-label::after{
  background-color: white;
}
.custom-control-input:checked~.custom-control-label::before {
    border-color: #00e004;
    background-color: #00e004;
}
.item-notif{
  display: inline-block;
  padding: 0.2rem 0.6rem;
  margin: 0 0.2rem;
  font-size: 0.5rem;
  font-weight: 600;
  transition: 0.2s;
  color: black;
}
.new-notif, .popular-notif, .special-notif{
  border-radius: 15px;
  cursor: default;
}
.new-notif{
  background-color: #00e004;
}
.popular-notif{
  background-color: #ff9000;
}
.special-notif{
  background-color: #e30a07;
}
.notif-off{
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}
.notif-off:hover{
  transition: 0.2s;
  background-color: #ffe100;
  border-radius: 15px;
}
.btn-div{
    text-align: center;
}
.form-btn{
  transition: 0.2s;
    font-family: 'Ubuntu', sans-serif;
    background-color: #202020;
    color: white;
    border: 0;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    margin: 0.2rem 1.5rem;
}
.add-sub-btn:hover{
  transition: 0.2s;
  background-color: #ffe100;
  color: black;
}
.submit-btn:hover{
    transition: 0.2s;
    background-color: #64d115;
    color: black;
}
.delete-btn:hover, .del-btn:hover{
    transition: 0.2s;
    background-color: #e30a07;
    color: black;
}
.menu-list-div{
  padding: 0 0.8rem;
}

.qr-code-div a canvas{
  border: 5px solid white;
}
/* **********************Mobile compatibility************************** */
@media (max-width: 1280px){
  .item-notif{
    padding: 0.2rem 0.5rem;
    margin: 0 0.15rem;
    font-size: 0.4rem;
    font-weight: 600;
  }

}
@media (max-width: 767px){

  .parent-div{
    background-color: #202020;
  }
  .searchBar{
    margin-bottom: 0.5rem;
  }
  .main-container .menu-col{
      margin-top: 1rem;
  }
  .menu-list-div{
    margin-left: 0.5rem;
  }
}
@media (min-width: 992px){
  .hidden{
    display: none;
  }
}
@media (max-width: 991px){
  .menu-category{
      display: none;
  }
  .hidden{
      margin: 1rem 2rem;
  }
  .hidden .btn{
      background-color: #303030;
      border: 0;
      border-radius: 0;
      padding: 1rem 1.5rem;
      color: white;
  }
  .hidden .btn:hover{
      background-color: #202020;
  }
  .hidden .btn.btn-secondary{
      background-color: #202020;
      border: 0;
  }
  .hidden .dropdown-menu{
      background-color: #303030;
      color: white;
      width: 15rem;
      padding: 0;
  }
  .hidden .dropdown-menu ul{
      padding: 0;
      margin: 0;
  }
  .hidden .dropdown-menu ul li{
      display: block;
      font-family: 'Ubuntu', sans-serif;
      color: #e0e0e0;
      background-color: #303030;
      font-size: 1.1rem;
      float: left;
      letter-spacing: 0.3rem;
      list-style: none;
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0;
      cursor: pointer;
  }
  .hidden .dropdown-menu ul li:hover{
    background-color: #202020;
    font-size: 1.2rem;
  }
}
@media (max-width: 600px){
  .div-to-show-menu {
      height: 200vh;
  }
}
