.restaurant-name-holder {
    padding: 0.5rem 1rem;
    margin: 0;
    color: white;
    cursor: pointer;
}
.restaurant-name-holder:hover{
    background-color: #202020;
}
.restaurant-name-holder .col-10{
    padding: 0;
    margin: 0.5rem 0;
}
.restaurant-name-holder .col-2{
    padding: 1rem;
    transition: 0.1s;
}
.restaurant-name-holder .col-2:hover{
    transition: 0.2s;
    border-radius: 30px 30px 2px 30px;
    background-color: #ffe100;
    color: black;
}
.drop-down-options{
    background-color: #ffe100;
    color: black;
    border-radius: 5px 2px 5px 5px;;
    position: absolute;
    top: 3.7rem;
    right: 0;
    width: 13rem;
    padding: 0.5rem 0;
    z-index: 2;
    box-shadow: 0 0 2px #303030;
}
.drop-down-options b{
    font-size: 0.9rem;
    display: block;
    margin: 0.1rem;
}
.drop-down-options b:hover{
    font-size: 1rem;
}

/* **********************Modal Styling*********************** */
/* ***********Table QR code**************** */
.dropdown-table-no{
    text-align: center;
    margin-bottom: 1rem;
}
.dropdown-table-no .btn{
    background-color: #303030;
    width: 15.4rem;
    border: 0;
    border-radius: 30px;
    padding: 1rem 1.5rem;
    color: white;
}
.dropdown-table-no .btn.btn-secondary:hover{
    background-color: #ffe100;
    color: black;
}
.dropdown-table-no .btn.btn-secondary{
    background-color: #202020;
    border: 0;
}
.dropdown-table-no .dropdown-menu{
    background-color: #303030;
    color: white;
    width: 16rem;
    padding: 0;
    border: 0.2rem solid #202020;
    padding: 0.2rem;
}
.dropdown-table-no .dropdown-menu ul{
    padding: 0;
    margin: 0;
    text-align: center;
}
.dropdown-table-no .dropdown-menu ul li{
    width: 3rem;
    font-family: 'Ubuntu', sans-serif;
    color: #e0e0e0;
    background-color: #303030;
    font-size: 1.1rem;
    float: left;
    letter-spacing: 0.3rem;
    list-style: none;
    padding: 0.5rem 0;
    margin: 0;
    cursor: pointer;
    border-radius: 10px;
}
.dropdown-table-no .dropdown-menu ul li:hover{
  background-color: #ffe100;
  color: black;
}
.current-tableNo span{
    text-align: center;
    background-color: white;
    box-shadow: 0 0 4px black;
    color: black;
    width: 1.9rem;
    height: 1.9rem;
    padding: 0.1rem 0.2rem 0.3rem;
    border-radius: 1rem;
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
}
.qr-code-div canvas {
    margin: 0 0 0.5rem;
    border: 5px solid white;
}
.qr-code-div, .current-tableNo {
    text-align: center;
}
/* Kitchen Email Form */
.qr-code-div .dropdown-menu{
    background-color: #f0f0f0;
    border-radius: 20px;
    right: 10rem;
    color: black;
    width: 93%;
    padding: 0;
    box-shadow: 0 0 4px black;
}
.qr-code-div .email-access-btn{
  width: 96.5%;
  background-color: #282828;
  color: white;
  border: 0;
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}
.email-dropdown{
    width: 100%;
}
.email-dropdown ul{
    width: 100%;
    padding: 0;
    margin: 0;
}
.email-ind{
    list-style: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
}
.email-ind:hover{
    background-color: #fff;
}
.email-ind button{
    transition: 0.2s;
    background-color: #202020;
    color: white;
    font-size: 0.7rem;
    text-align: center;
    margin: 0.15rem 0;
    padding: 0.1rem 0.5rem;
    border: 0;
    border-radius: 10px;
    float: right;
}
.email-ind button:hover{
    transition: 0.2s;
    background-color: #e30a07;
    color: black;
    letter-spacing: 1px;
}
.qr-code-div .form-group{
    display: block;
}
.qr-code-div .form-control{
    background-color: #282828;
    text-align: center;
    border-radius: 20px;
}
.qr-code-div .form-control:hover, .qr-code-div .email-access-btn:hover{
    background-color: #202020;
}
.qr-code-div .add-email{
    font-family: 'Ubuntu', sans-serif;
    background-color: #ffe100;
    color: black;
    border: 0;
    border-radius: 20px;
    padding: 0.5rem 1.5rem;
    margin: 0.8rem 0;
}
.qr-code-div .add-email:hover{
    background-color: #ffff33;
}
