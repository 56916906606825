.log-out-button {
    background-color: unset;
    border: 0.5px solid;
    border-color: #202020;
    border-radius: 20px;
    color: #e0e0e0;
    margin-top: 0.35rem;
}
.logOut-text{
  margin: auto 0.5rem;
  letter-spacing: 1px;
}
.log-out-button:hover {
    background-color: #f0f0f0;
    color: #000;
    border: 0.5px solid;
    border-color: #f0f0f0;
}

@media (max-width: 991px){

  .log-out-button {
      margin-right: 0;
      margin-bottom: 1rem;
  }

}
