.log-in-btn{
    text-align: center;
    padding: 0;
}
.btn-space{
    text-align: center;
}
.google-button-a-tag {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none !important;
}
.button-label:hover{
    color: white;
}
.button {
    display: inline-block;
    border: 0;
    padding: 4px 18px;
    text-align: left;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
    color: #e0e0e0 !important;
    font: inherit;
    outline: none;
}

.button-label{
  position: relative;
  top: 0.15rem;
  margin-top: 5%;
}

.button .svgIcon {
 vertical-align: middle;
 padding-right: 4px;
 height: 37px;
 display: inline-block;
}
