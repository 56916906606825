.show-menu-cards {
    margin: 1% 0.5%;
    float: left;
    border: 0;
    width: 32%;
    border-radius: 6px;
}

.show-menu-cards .card-body{
  display: inline-block;
  border-radius: 5px 5px 0 0;
  background-color: #303030;
  min-height: 5rem;
}
.show-menu-cards .item-name{
  display: inline-block;
  font-family: 'Noto Serif', serif;
  font-size: 1.2rem;
  padding-top: 0.8rem;
}
.show-menu-cards .vnv-id{
  width: 2rem;
}
.show-menu-cards .item-description{
  font-size: 0.9rem;
  color: #e0e0e0;
  text-shadow: 1px 1px 3px #101010;
  height: 2rem;
  line-height: 1.3;
}
.show-menu-cards .item-price{
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.2rem;
  margin: 0;
}
.show-menu-cards .item-quantity{
  float: right;
  padding-right: 0.5rem;
  background-color: black;
}
.show-menu-cards .card-footer{
  background-color: #202020;
  text-align: center;
  padding: 0;
  border-radius: 0 0 5px 5px;
  border: 0;
}
.show-menu-cards .card-footer .col{
  padding: 0;
}
.show-menu-cards .card-footer.row{
  margin: 0;
}
.show-menu-cards .add-to-cart, .in-cart{
  font-family: 'Ubuntu', sans-serif;
  width: 100%;
  background-color: #202020;
  border-radius: 0 0 5px 5px;
  border: 0;
}
.add-to-cart:hover{
  background-color: #64d115;
  color: black;
}
.show-menu-cards .add-to-cart::after{
  background-color: #64d115;
}
.in-cart{
  padding: 0.375rem;
}
.subc-drop{
    display: inline;
}
.subc-drop .btn.btn-secondary{
    background-color: #202020;
    border: 0;
    border-radius: 0 0 10px 0;
    margin: 0;
    color: white;
    width: 100%;
}
.subc-drop .btn:hover{
    background-color: #ffe100;
    color: black;
}
.subc-drop .btn{
    background-color: #202020;
    border: 0;
}
.subc-drop .dropdown-menu{
    background-color: #303030;
    border-radius: 5px;
    box-shadow: 0 0 3px #ffe100;
    width: 100%;
    padding: 0.5rem;
    margin: 0;
}
.subc-drop .dropdown-item{
    margin: 0;
    padding: 0;
}
.subc-drop-head{
    display: flex;
    color: white;
}
.subc-drop-head:hover{
    background-color: #303030;
    color: white;
    cursor: default;
}
.subc-drop .dropdown-menu li{
    color: white;
    background-color: #303030;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 5px;
}
.sub-row{
    margin: 0;
}
.sub-price-col{
    text-align: right;
}
@media (max-width: 1231px){
  .show-menu-cards {
      width: 49%;
  }
}
@media (max-width: 900px)
{
  .show-menu-cards .item-name{
    font-size: 1.3rem;
  }
  .show-menu-cards .vnv-id{
    width: 2rem;
  }
  .show-menu-cards .item-description{
    font-size: 0.8rem;
    line-height: 1.2;
  }
  .show-menu-cards .item-price{
    padding-bottom: 0.5rem;
  }
  .show-menu-cards .card-footer{
    background-color: #202020;
    padding: 1% 2% 2%;
  }
  .show-menu-cards .qty-div{
    text-align: center;
    width: 49%;
    padding-top: 0.5rem;
    display: inline-block;
    height: 3rem;
  }
}
@media (max-width: 476px){
  .show-menu-cards .card-body{
    height: 12rem;
  }
  .show-menu-cards {
      margin: 1% 0.5%;
      float: left;
      border: 0;
      width: 98%;
      border-radius: 6px;
  }

}
