.nav-div-fixed{
    width: 100%;
    z-index: 18;
}
.main-div {
    overflow: hidden;
    margin: 0;
}
.front-title-div{
  background-image: url("../images/chillyBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #202020;
  padding: 10%;
  color: #101010;
  text-shadow: 1px 1px 3px #ffa100;
  text-align: center;
}
.front-title-div h1{
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.2rem;
}
.front-parent-div {
    background-color: #202020;
    padding: 7% 10%;
}
.front-row .col-7{
  padding-top: 3rem;
  padding-bottom: 2rem;
  font-size: 1.5rem;
  font-family: 'Nunito Sans', sans-serif;
  text-shadow: 2px 2px 4px black;
}
.p-right{
  padding-left: 4rem;
}
.p-left{
  padding-right: 4rem;
}
.h3-left, .h3-right{
  position: absolute;
  z-index: 1;
  bottom: 1rem;
  font-family: 'Permanent Marker', cursive;
  background-color: rgb(255, 225, 0, 0.9);
  color: black;
  padding: 0.5rem 1.5rem;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.5);
}
.h3-left{
  left: 3rem;
}
.h3-right{
  right: 3rem;
}
.btn-div{
    color: #e0e0e0;
    margin: 0.5rem 1rem 0 0;
}
.d-block{
  width: inherit;
  border-radius: 3px;
  box-shadow: 2px 2px 4px black;
}
#pricing-section {
    box-shadow: -1px 0 5px black;
    background-color: #fff110;
    color: white;
    padding: 5% 7%;
    text-align: center;
}
.pricing-heading{
    color: #101010;
    font-family: 'Righteous', cursive;
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 1rem;
    padding: 2% 0;
}
.pricing-card{
    background-color: #101010;
    border-radius: 2%;
    height: 19rem;
}
.card-header{
    color: #ffe100;
    font-family: 'Ubuntu', sans-serif;
}
.price-text{
    padding: 2%;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.2;
}
.pricing-col{
    padding: 3% 2%;
}
.trial-text{
    line-height: 2;
    padding: 0 10%;
}
.signUp-button-from-pricing {
    text-decoration: none !important;
}
.faq-div{
  background-color: #202020;
}
@media (max-width: 1200px){
  .front-title-div h1{
    font-size: 2rem;
  }
  .h3-left, .h3-right{
    font-size: 1.2rem;
  }
  .front-row .col-7{
    font-size: 0.9rem;
  }
}
@media (max-width: 991px) {
  .front-title-div h1{
    font-size: 1.7rem;
  }
  .front-title-div h3{
    font-size: 1.2rem;
  }
  .carousel-item{
    height: 80vh;
  }
  .front-parent-div{
    padding: 3% 5%;
  }
  .pricing-heading{
    font-size: 3rem;
    letter-spacing: 0.8rem;
  }
}
@media (max-width: 751px){
  .h3-left, .h3-right{
    font-size: 0.8rem;
    padding: 0.3rem 0.8rem;
  }
  .front-row .col-7{
    font-size: 0.7rem;
  }
  .front-row .col-7{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
@media (max-width: 600px) {
  .pricing-heading{
    font-size: 2.7rem;
    letter-spacing: 0.6rem;
  }
  .h3-left, .h3-right{
    font-size: 0.65rem;
  }
  .h3-left{
    left: 2rem;
    bottom: 1rem;
  }
  .h3-right{
    right: 2rem;
    bottom: 0.5rem;
  }
  .front-row .col-7{
    font-size: 0.5rem;
  }
}
@media (max-width: 450px) {
  .pricing-heading{
    font-size: 2rem;
    letter-spacing: 0.4rem;
  }
}
