.card-for-item {
    margin: 1% 0.5%;
    float: left;
    border: 0;
    width: 48%;
    box-shadow: 0 0 3px #ffe100;
    border-radius: 8px;
}
.card-body{
    border-radius: 7px 7px 0 0;
    background-color: #202020;
    color: white;
    padding: 0 1rem;
    height: 9rem;
}
.card-for-item .item-name{
    display: inline-block;
    font-family: 'Noto Serif', serif;
    font-size: 1.2rem;
    padding-top: 0.6rem;
}
.na-icon{
  color: red;
}
.notif-div{
  padding: 0.2rem 0;
}
.vnv-id{
    width: 2rem;
    float: right;
    padding-top: 0.3rem;
}
.item-description{
  font-size: 0.9rem;
  margin: 0;
}
.card-for-item .card-footer{
    padding: 0 0.5rem;
    background-color: #303030;
    color: white;
    border-radius: 0 0 7px 7px;
}
.card-for-item .item-price{
    display: inline-block;
    font-size: 1rem;
    margin: 0.8rem;
}
.cardBtn-div{
    float: right;
    margin: 0.5rem 0;
}
.sub-drop{
    display: inline;
}
.sub-drop .btn.btn-secondary{
    background-color: #202020;
    border: 0;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    color: white;
}
.sub-drop .btn:hover{
    background-color: #181818;
}
.sub-drop .btn{
    background-color: #202020;
    border: 0;
}
.sub-drop .dropdown-menu{
    background-color: #303030;
    border-radius: 5px;
    box-shadow: 0 0 3px #ffe100;
    width: 300%;
    padding: 0.5rem;
    margin: 0;
}
.sub-drop .dropdown-item{
    margin: 0;
    padding: 0;
}
.sub-drop-head{
    display: flex;
    color: white;
}
.sub-drop-head:hover{
    background-color: #303030;
    color: white;
    cursor: default;
}
.sub-drop .dropdown-menu li{
    color: white;
    background-color: #303030;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 5px;
}
.sub-row{
    margin: 0;
}
.sub-price-col{
    text-align: right;
}
.card-btn{
    font-family: 'Ubuntu', sans-serif;
    background-color: #202020;
    color: white;
    border: 0;
    border-radius: 3px;
    padding: 0.3rem 0.8rem;
    margin: 0 0.5rem;
}
.edit-btn:hover{
    transition: 0.2s;
    background-color: #ffe100;
    color: #092532;
}
@media (max-width: 1027px)
{
  .card-btn{
      margin: 0 0.5rem 0.5rem;
  }
}

@media (max-width: 900px)
{
  .item-name{
    font-size: 1.3rem;
  }
  .vnv-id{
    width: 2rem;
  }
  .item-description{
    font-size: 0.8rem;
  }
  .qty-div{
    text-align: center;
    width: 49%;
    padding-top: 0.5rem;
    display: inline-block;
    height: 3rem;
  }
}

@media (max-width: 600px){
  .card-for-item {
      width: 96%;
  }
  .item-price{
      font-family: 'Ubuntu', sans-serif;
      font-size: 0.9rem;
  }
  .card-btn{
      font-size: 0.8rem;
      border-radius: 3px;
      padding: 0.3rem 0.8rem;
      margin: 0 0.4rem;
  }
}
